export const scrollToSection = (elementId) => {
  const element = document.getElementById(elementId);
  
  if (element) {
    const offset = 80; // Navbar height (64px) + some padding
    const elementPosition = element.offsetTop - offset;
    
    window.scrollTo({
      top: elementPosition,
      behavior: 'smooth'
    });
  }
}; 