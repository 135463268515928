import React from 'react';

const projects = [
  {
    title: 'iOS App Design',
    description: 'Modern and intuitive user interface design for iOS applications',
    image: '/design.jpg',
  },
  {
    title: 'iOS Development',
    description: 'Custom iOS app development with cutting-edge technologies',
    image: '/development.jpg',
  },
  {
    title: 'App Store Optimization',
    description: 'Strategic app store presence and marketing optimization',
    image: '/optimazation.jpg',
  },
];

export default function Solutions() {
  return (
    <section id="solutions" className="py-20 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold text-gray-900">Solutions</h2>
          <p className="mt-4 text-lg text-gray-600">
            Explore our comprehensive iOS development solutions and services
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {projects.map((project, index) => (
            <div key={index} className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300">
              <img
                src={project.image}
                alt={project.title}
                className="w-full h-48 object-cover"
              />
              <div className="p-6">
                <h3 className="text-xl font-semibold text-gray-900 mb-2">
                  {project.title}
                </h3>
                <p className="text-gray-600">{project.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
} 