import React from 'react';
import { scrollToSection } from '../utils/scroll';

export default function Hero() {
  return (
    <section id="home" className="relative pt-20 overflow-hidden bg-gradient-to-b from-white to-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-32">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
          <div className="relative z-10">
            <h1 className="text-5xl md:text-6xl font-bold text-gray-900 leading-tight mb-6">
              Creating <span className="text-primary">Exceptional</span> iOS Applications
            </h1>
            <p className="text-xl text-gray-600 mb-8 leading-relaxed">
              We specialize in designing and developing cutting-edge iOS applications
              that deliver outstanding user experiences and drive business growth.
            </p>
            <a
              href="#contact"
              onClick={(e) => {
                e.preventDefault();
                scrollToSection('contact');
              }}
              className="btn-primary"
            >
              Get Started
              <svg className="ml-2 -mr-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </a>
          </div>
          <div className="relative">
            <div className="absolute inset-0 bg-gradient-to-tr from-primary/30 to-secondary/30 rounded-2xl transform rotate-6"></div>
            <div className="relative rounded-2xl shadow-2xl transform transition-transform duration-300 hover:-translate-y-2 overflow-hidden">
              <iframe 
                title="iPhone Animation"
                allowFullScreen 
                style={{ border: 'none', width: '100%', height: '500px' }}
                src="https://www.pacdora.com/share?filter_url=psvjs8i1cr"
              />
            </div>
          </div>
        </div>
      </div>
      
      <div className="absolute top-0 right-0 -translate-y-12 translate-x-12 transform">
        <svg width="404" height="384" fill="none" viewBox="0 0 404 384">
          <defs>
            <pattern id="pattern-squares" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="384" fill="url(#pattern-squares)" />
        </svg>
      </div>
    </section>
  );
} 